import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, map, shareReplay, takeUntil, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { EMAIL_PATTERN } from '../../../core/constants/help-menu.constants';
import { AuthCognitoService } from '../../../core/services/auth-cognito.service';
import { LoggerService } from '../../../core/services/logger.service';
import { LoadingService } from '../../../services';
import { ErrorConfig } from '../../../shared/directives/control-errors/control-errors.token';
import { untilDestroy } from '../../../shared/utilities';
import { setUserProfile } from '../../../store/user-profile/actions/user-profile.actions';
import { hexDecode, initiatePerformSetPassword, initiateSignInProcess } from '../sign-up-helper';
import { FAMILY_NAME, GIVEN_NAME, NEW_PASSWORD_REQUIRED } from '../sign-up.constants';

export interface SignUpForm {
  username: string;
  password: string;
}

@Component({
  selector: 'carbon-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent extends untilDestroy(class {}) {
  signUpForm: FormGroup;
  isLoading$ = this.loadingService.isLoading$;
  hasError$$ = new BehaviorSubject<boolean>(false);
  oldPassword = '';
  inboundId: string | null = null;
  isSignUpContext = false;
  isSuccessPathFinder = false;
  pathFinderSuccessUrl!: string;
  pcfUuid!: string;

  hasError$ = this.hasError$$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  emailErrors: ErrorConfig = {
    required: () => 'SignUp.Form.EmailRequiredError',
    email: () => 'SignUp.Form.EmailPatternError',
    pattern: () => 'SignUp.Form.EmailPatternError',
  };

  passwordErrors: ErrorConfig = {
    required: () => 'SignUp.Form.PasswordRequiredError',
    pattern: () => 'SignUp.Form.PasswordPatternError',
  };

  confirmPasswordErrors: ErrorConfig = {
    required: () => 'SignUp.Form.PasswordRequiredError',
    passwordNotMatch: () => 'SignUp.Form.PasswordMismatchError',
  };

  constructor(
    private router: Router,
    private authService: AuthCognitoService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private loadingService: LoadingService,
    private store: Store,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    super();

    this.isSignUpContext = this.router.url.startsWith('/sign-up');
    this.signUpForm = this.createSignUpForm();

    if (this.router.url.startsWith('/set-pathfinder-credentials-error')) {
      this.hasError$$.next(true);
    } else if (this.router.url.startsWith('/set-pathfinder-credentials-success')) {
      this.route.queryParams.subscribe((params) => {
        const pcfUuid = params['pcf_uuid'];
        this.pathFinderSuccessUrl = environment.pathfinderBaseUrl + '/' + pcfUuid;
        this.pcfUuid = pcfUuid;
      });
      this.isSuccessPathFinder = true;
    }

    this.signUpForm.valueChanges
      .pipe(debounceTime(300), takeUntil(this.destroy$$))
      .subscribe(() => {
        this.hasError$$.next(false);
      });
    this.route?.queryParamMap
      .pipe(
        takeUntil(this.destroy$$),
        tap((params) => {
          if (params.has('Pcf') && params.get('Pcf')) {
            this.inboundId = params.get('Pcf');
          }
        }),
        map((params) => [params.get('link')]),
      )
      .subscribe(([link]) => {
        if (link) {
          // Decode the encrypted link to retrieve credentials
          const credentials = hexDecode(link);

          if (this.isSignUpContext) {
            // Split credentials for sign-up context, keeping the first part as username and the rest as password
            const [username, ...password] = credentials.split(':::');
            if (username && password) {
              this.signUpForm.patchValue({ username });
              this.signUpForm.get('username')?.disable();
              this.oldPassword = password.join(':::');
            }
          } else {
            // Split credentials expecting username, password, and a UUID for pathfinder
            const [username, password, pcf_uuid] = credentials.split(':::');

            // Configure the URL and UUID for pathfinder
            this.pathFinderSuccessUrl = environment.pathfinderBaseUrl + '/' + pcf_uuid;
            this.pcfUuid = pcf_uuid;

            if (username && password) {
              // Set the username, disable the username field, and store the password for other contexts
              this.signUpForm.patchValue({ username });
              this.signUpForm.get('username')?.disable();
              this.oldPassword = password;
            }
          }
        }
      });
  }

  performSignUpOrPathFinder(): void {
    if (!this.signUpForm.valid) {
      return;
    }

    initiateSignInProcess(
      this.signUpForm.getRawValue(),
      this.authService,
      this.oldPassword,
      this.loadingService,
    )
      .pipe(takeUntil(this.destroy$$))
      .subscribe({
        next: (user) => {
          if (user.challengeName === NEW_PASSWORD_REQUIRED) {
            this.performSetPassword(user);
          }
        },
        error: (error) => {
          this.hasError$$.next(true);
          this.logger.logError(
            `${this.translate.instant('SignUp.Form.AttemptToSignUpFailed')}, ${error}`,
          );
        },
      });
  }

  performSetPassword(user: unknown): void {
    initiatePerformSetPassword(
      this.signUpForm.getRawValue(),
      this.authService,
      user,
      this.loadingService,
    )
      .pipe(takeUntil(this.destroy$$))
      .subscribe({
        next: (user) => {
          const profile = {
            firstName: user.challengeParam.userAttributes[GIVEN_NAME],
            lastName: user.challengeParam.userAttributes[FAMILY_NAME],
            email: user.username,
          };
          if (this.isSignUpContext) {
            if (this.inboundId) {
              //for first time redirect to inbound to show answer pcf modal
              this.router.navigateByUrl(`/requests/overview/inbound?Pcf=${this.inboundId}`);
            } else {
              this.router.navigateByUrl('/products/overview');
            }
          } else {
            this.isSuccessPathFinder = true;
            this.router.navigateByUrl(
              `/set-pathfinder-credentials-success?pcf_uuid=${this.pcfUuid}`,
            );
          }

          this.store.dispatch(setUserProfile({ profile }));
        },
        error: (error) => {
          this.hasError$$.next(true);
          this.logger.logError(
            `${this.translate.instant('SignUp.Form.AttemptToSignUpFailed')}, ${error}`,
          );
        },
      });
  }

  private createSignUpForm() {
    return this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/),
        ],
      ],
      confirm_password: [
        '',
        Validators.compose([Validators.required, this.passwordMatcher.bind(this)]),
      ],
    });
  }

  // confirm new password validator
  private passwordMatcher(control: FormControl): { [p: string]: boolean } | null {
    if (this.signUpForm && control.value !== this.signUpForm.controls['password'].value) {
      return { passwordNotMatch: true };
    }
    return null;
  }
}
