<p-toast></p-toast>
<div class="login-page flex flex-column min-h-screen justify-content-center align-items-center login-bg p-6">
  <div class="grid login-container flex-nowrap">
    <div class="hidden xl:block p-6 col-6 info-block">
      <section class="header-section">
        <h3 class="header-text">{{ (isShift ? 'Login.Info.ShiftHeader' : 'Login.Info.Header') | translate }}</h3>
        <p class="header-sub font-normal">
          {{ 'Login.Info.SubHeader' | translate }}
        </p>
      </section>
      <section class="flex align-items-start info-section">
        <div class="section-icon">
          <i class="info-icon first"></i>
        </div>
        <div class="section-text">
          <h5 class="info-header-text">{{ 'Login.Info.Section1Header' | translate }}</h5>
          <p class="font-normal">
            {{ 'Login.Info.Section1Text' | translate }}
          </p>
        </div>
      </section>
      <section class="flex align-items-start info-section">
        <div class="section-icon">
          <i class="info-icon second"></i>
        </div>
        <div class="section-text">
          <h5 class="info-header-text">{{ 'Login.Info.Section2Header' | translate }}</h5>
          <p class="font-normal">
            {{ 'Login.Info.Section2Text' | translate }}
          </p>
        </div>
      </section>
      <section class="flex align-items-start info-section">
        <div class="section-icon">
          <i class="info-icon third"></i>
        </div>
        <div class="section-text">
          <h5 class="info-header-text">{{ 'Login.Info.Section3Header' | translate }}</h5>
          <p class="font-normal">
            {{ 'Login.Info.Section3Text' | translate }}
          </p>
        </div>
      </section>
    </div>

    <div
      class="col-12 col-offset-0 xl:col-offset-0 xl:col-6 p-6 sign-in-block"
      [ngClass]="{ 'sign-in-block--shift-screen': isShift }"
    >
      <div class="flex flex-wrap justify-content-between pb-6 shift-screen-logo-container" *ngIf="isShift">
        <img class="h-3rem p-1" src="/assets/images/SHIFT_icon.png" alt="Shift Logo" />
        <img class="h-3rem p-1" src="/assets/images/chips_icon.png" alt="Chips Logo" />
        <img class="h-3rem p-1" src="/assets/images/european_union_icon.png" alt="European Union Logo" />
      </div>
      <!-- Logo -->
      <div class="flex align-items-center mb-3">
        <div class="logo" [ngClass]="{ 'has-error': hasError$ | async }"></div>
      </div>

      <p-message
        *ngIf="hasError$ | async"
        styleClass="login-error-message"
        severity="error"
        [text]="'Login.LoginForm.LoginErrorMessage' | translate"
      ></p-message>

      <ng-container>
        <ng-container *ngIf="!isHubspotFormVisible; else hubspotFormTemplate">
          <h3 class="header">{{ 'Login.LoginForm.Header' | translate }}</h3>
          <form
            [formGroup]="loginForm"
            class="flex flex-1 flex-column justify-content-start"
            (ngSubmit)="login()"
            carbonFormSubmit
          >
            <div class="field p-fluid email-field">
              <span class="p-float-label" carbonControlErrorsContainer>
                <input
                  id="email"
                  type="text"
                  pInputText
                  formControlName="username"
                  carbonControlErrors
                  [customErrors]="emailErrors"
                />
                <label for="email">{{ 'Global.Email' | translate }}</label>
              </span>
            </div>

            <div class="field p-fluid password-field">
              <span class="p-float-label" carbonControlErrorsContainer>
                <input
                  id="password"
                  type="password"
                  pInputText
                  formControlName="password"
                  carbonControlErrors
                  [customErrors]="passwordErrors"
                />
                <label for="password">{{ 'Global.Password' | translate }}</label>
              </span>
            </div>

            <div class="field-checkbox remember-me-field">
              <p-checkbox formControlName="rememberMe" [binary]="true" inputId="rememberMe"></p-checkbox>
              <label for="rememberMe">{{ 'Login.LoginForm.RememberMe' | translate }}</label>
            </div>

            <div class="grid w-full gap-2">
              <button
                pButton
                pRipple
                label="{{
                  ((isLoading$ | async)!
                    ? 'Login.LoginForm.LoginButtonProgressText'
                    : 'Login.LoginForm.LoginButtonText'
                  ) | translate
                }}"
                class="mt-auto sign-in-button flex justify-content-center col-6"
                type="submit"
                [loading]="(isLoading$ | async)!"
              ></button>
              <button
                pButton
                pRipple
                type="button"
                (click)="openHubspotForm()"
                label="{{ 'Login.LoginForm.StartFreeTrialButtonText' | translate }}"
                class="p-button-text mt-auto sign-in-button flex justify-content-center"
              ></button>
            </div>
          </form>
        </ng-container>

        <!-- Go back button -->
        <ng-template #hubspotFormTemplate>
          <div class="flex gap-2 align-items-center mb-4">
            <button class="p-button-text" pButton pRipple icon="pi pi-arrow-left" (click)="closeHubspotForm()"></button>
            <h3 class="header m-0">{{ 'Login.LoginForm.StartFreeTrialHeader' | translate }}</h3>
          </div>

          <div id="hubspotForm"></div>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <div class="mt-5 footer-container" *ngIf="isShift; else notShift">
    <p class="font-normal footer-link text-sm" [innerHTML]="'Login.FooterText' | translate"></p>
  </div>

  <ng-template #notShift>
    <div class="mt-6">
      <p class="font-normal footer-link" [innerHTML]="'Login.FooterLink' | translate: { websiteUrl }"></p>
    </div>
  </ng-template>
</div>
