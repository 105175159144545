import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map, of } from 'rxjs';

import { AmplifyApiService } from '../core/services/amplify-api.service';
import { AmplifyApiConfig } from '../shared/models/amplify-api-config.interface';
import { CarbonResponse, CarbonResponseBody } from '../shared/models/api.interfaces';
import { Notification } from '../shared/models/notification.interface';
import { CarbonProductComponent } from '../shared/models/product-component.interfaces';
import { CarbonProductDetailsResponse } from '../shared/models/product-details.interface';
import { CarbonProductInboundRequest } from '../shared/models/product-inbound-request.domain';
import { CarbonProductOverviewGridItem } from '../shared/models/products-overview.interfaces';
import { RequestPcfPayload } from '../shared/models/request-pcf-payload.interface';
import { SharePcfPayload } from '../shared/models/share-pcf-payload.interface';
import { CarbonSuppliersOverviewGridItem } from '../shared/models/suppliers/suppliers-overview.interfaces';
import { TenantsConfig } from '../shared/models/tenants-config.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiToStoreCallsService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  productsServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/products',
  };

  // TODO: Will move api version (v2) in global const file
  suppliersServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/v2/supplier',
  };

  suppliersComponentsServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/components?supplierId=',
  };

  updateSupplierServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/v2/supplier/',
  };

  componentsServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/components?productId=',
  };
  requestPcfServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/components/{id}/outgoing',
  };
  InboundServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/v2/incoming/{id}',
  };
  notifcationServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/notifications',
  };

  tenantsServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/tenants',
  };

  dataSetServiceLinkAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/link',
  };
  sharePcfServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/{id}/sharepcf',
  };

  // TODO: remove HttpClient when fake data is gone
  constructor(
    private apiService: AmplifyApiService,
    private http: HttpClient,
  ) {}

  getProducts(): Observable<CarbonProductOverviewGridItem[]> {
    return this.apiService.get<CarbonProductOverviewGridItem[]>(this.productsServiceAmplifyConfig);
  }

  getSuppliers(): Observable<CarbonSuppliersOverviewGridItem[]> {
    return this.apiService.get(this.suppliersServiceAmplifyConfig);
  }

  updateSupplierById(
    id: string,
    payload: CarbonSuppliersOverviewGridItem,
    locallyHandleErrors?: boolean,
  ): Observable<CarbonResponse<void>> {
    return this.apiService.patch(
      {
        ...this.updateSupplierServiceAmplifyConfig,
        path: this.updateSupplierServiceAmplifyConfig.path + id,
      },
      payload,
      locallyHandleErrors,
    );
  }

  getComponentsByProductId(id: string): Observable<CarbonProductComponent[]> {
    return this.apiService.get<CarbonProductComponent[]>({
      ...this.componentsServiceAmplifyConfig,
      path: this.componentsServiceAmplifyConfig.path + id,
    });
  }

  getComponentsBySupplierId(id: string): Observable<CarbonProductComponent[]> {
    return this.apiService.get<CarbonProductComponent[]>({
      ...this.suppliersComponentsServiceAmplifyConfig,
      path: this.suppliersComponentsServiceAmplifyConfig.path + id,
    });
  }

  sendRequestPcf(payload: RequestPcfPayload, locallyHandleErrors?: boolean) {
    return this.apiService.post<RequestPcfPayload, CarbonResponse<void>>(
      {
        ...this.requestPcfServiceAmplifyConfig,
        path: this.requestPcfServiceAmplifyConfig.path.replace('{id}', payload.componentId),
      },
      payload,
      locallyHandleErrors,
    );
  }

  //use id to get product information from api
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getProductInboundRequest(id: string): Observable<CarbonProductInboundRequest> {
    return this.apiService.get<CarbonProductInboundRequest>({
      ...this.InboundServiceAmplifyConfig,
      path: this.InboundServiceAmplifyConfig.path.replace('{id}', id),
    });
  }

  // keep it until backend will be stable
  getFakeProducts(): Observable<CarbonProductOverviewGridItem[]> {
    return this.http
      .get<CarbonResponse<CarbonProductOverviewGridItem[]>>('assets/mocks/products.json')
      .pipe(map((response: CarbonResponse<CarbonProductOverviewGridItem[]>) => response.body.data));
  }

  // keep it until backend will be stable
  getFakeComponentsForProduct(): Observable<CarbonProductComponent[]> {
    return this.http
      .get<CarbonResponse<CarbonProductComponent[]>>('assets/mocks/product-components.json')
      .pipe(map((response: CarbonResponse<CarbonProductComponent[]>) => response.body.data));
  }

  componentRequestQuote(
    productId: string,
    componentIds: string[],
  ): Observable<{ productId: string; componentIds: string[] }> {
    if (componentIds.length === 0) {
      return of({ productId, componentIds });
    }

    return this.apiService
      .post<{ productId: string; componentIds: string[] }, CarbonResponseBody<{ message: string }>>(
        {
          ...this.productsServiceAmplifyConfig,
          path: '/components/requestquote',
        },
        {
          productId,
          componentIds,
        },
      )
      .pipe(map(() => ({ productId, componentIds })));
  }

  getNotifications(): Observable<Notification[]> {
    this.isLoading$.next(true);
    return this.apiService
      .get<Notification[]>({
        ...this.notifcationServiceAmplifyConfig,
      })
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        }),
      );
  }

  updateNotification(id: string): Observable<CarbonResponse<void>> {
    return this.apiService.post<{ messageId: string }, CarbonResponse<void>>(
      {
        ...this.notifcationServiceAmplifyConfig,
        path: `${this.notifcationServiceAmplifyConfig.path}/ack`,
      },
      { messageId: id },
    );
  }

  getTenantsConfig(): Observable<TenantsConfig> {
    return this.apiService.get<TenantsConfig>({
      ...this.tenantsServiceAmplifyConfig,
      path: this.tenantsServiceAmplifyConfig.path + '/config',
    });
  }

  // Passing type as query string parameter to be able to
  // get different S3 links for different types of files
  getLink(type?: string): Observable<{ url: string }> {
    return this.apiService.get<{ url: string }>({
      ...this.dataSetServiceLinkAmplifyConfig,
      init: { queryStringParameters: { type } },
    });
  }

  getProduct(id: string): Observable<CarbonProductDetailsResponse> {
    return this.apiService.get<CarbonProductDetailsResponse>({
      ...this.productsServiceAmplifyConfig,
      path: `${this.productsServiceAmplifyConfig.path}/${id}`,
    });
  }

  sharePcf(productId: string, payload: SharePcfPayload) {
    return this.apiService.post<SharePcfPayload, CarbonResponse<void>>(
      {
        ...this.sharePcfServiceAmplifyConfig,
        path: this.sharePcfServiceAmplifyConfig.path.replace('{id}', productId),
      },
      payload,
    );
  }
}
