import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { CarbonProductInboundRequest } from 'src/app/shared/models/product-inbound-request.domain';

import { AmplifyApiService } from '../../../core/services/amplify-api.service';
import { AmplifyApiConfig } from '../../../shared/models/amplify-api-config.interface';
import { CarbonResponse } from '../../../shared/models/api.interfaces';
import { CarbonInboundRequest } from '../../../shared/models/inbound-request.domain';
import { CarbonOutboundRequest } from '../../../shared/models/outbound-request.domain';
import { CarbonTenant } from '../../../shared/models/tenants-config.interface';

@Injectable()
export class RequestsService {
  outboundRequestServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/v2/outgoing',
  };
  inboundRequestServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/v2/incoming',
  };
  tenantsServiceAmplifyConfig: AmplifyApiConfig = {
    apiName: 'CarbonBlockService',
    path: '/tenants',
  };

  constructor(
    private apiService: AmplifyApiService,
    private http: HttpClient,
  ) {}

  getOutboundRequests() {
    return this.apiService.get<CarbonOutboundRequest[]>(this.outboundRequestServiceAmplifyConfig);
  }

  getOutboundRequestsBySupplierName(supplierName: string) {
    return this.apiService.get<CarbonOutboundRequest[]>({
      ...this.outboundRequestServiceAmplifyConfig,
      init: {
        queryStringParameters: {
          supplierName,
        },
      },
    });
  }

  getTenantsAccount(supplierName: string) {
    return this.apiService.get<CarbonTenant[]>({
      ...this.tenantsServiceAmplifyConfig,
      path: this.tenantsServiceAmplifyConfig.path + '/search',
      init: {
        queryStringParameters: {
          column: 'companyName',
          value: supplierName,
        },
      },
    });
  }

  getInboundRequests() {
    return this.apiService.get<CarbonProductInboundRequest[]>(
      this.inboundRequestServiceAmplifyConfig,
    );
  }

  getOutboundRequestsMocks() {
    return this.http
      .get<CarbonResponse<CarbonOutboundRequest[]>>('assets/mocks/outbound-requests.json')
      .pipe(map((response) => response.body.data));
  }

  getInboundRequestsMocks() {
    return this.http
      .get<CarbonResponse<CarbonInboundRequest[]>>('assets/mocks/inbound-requests.json')
      .pipe(map((response) => response.body.data));
  }
}
