import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Amplify, Auth } from 'aws-amplify';
import { PrimeNGConfig } from 'primeng/api';
import { take } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { environment } from '../environments/environment';
import { preferredLanguageChange } from './store/user-preferences/actions/user-preferences.actions';
import { setUserProfile } from './store/user-profile/actions/user-profile.actions';

const USER_PREFERENCES_LOCAL_STORAGE_KEY = 'userPreferences';

@Component({
  selector: 'carbon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private primeConfig: PrimeNGConfig,
    private translate: TranslateService,
    private store: Store,
  ) {}

  private static setupAmplify() {
    Amplify.configure({
      Auth: environment.cognito.auth,
      API: environment.api,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    });
  }

  ngOnInit() {
    AppComponent.setupAmplify();
    this.setupTranslation();
    this.setupPrimeNG();
    this.setUserProfile();
  }

  private setupTranslation() {
    this.translate.addLangs(environment.translate.supportedLanguages);
    this.translate.setDefaultLang(environment.translate.defaultLanguage);

    const browserLanguage = this.translate.getBrowserLang();
    let preferredLanguage;
    let userPreferredLanguage;
    const { defaultLanguage, supportedLanguages } = environment.translate;

    try {
      const userPreferences = JSON.parse(
        localStorage.getItem(USER_PREFERENCES_LOCAL_STORAGE_KEY) || '',
      );
      userPreferredLanguage = userPreferences.preferredLanguage;
    } catch {
      if (userPreferredLanguage && supportedLanguages.includes(userPreferredLanguage)) {
        preferredLanguage = userPreferredLanguage;
      } else if (browserLanguage && supportedLanguages.includes(browserLanguage)) {
        preferredLanguage = browserLanguage;
      } else {
        preferredLanguage = defaultLanguage;
      }
    }

    this.translate.use(preferredLanguage);
    this.store.dispatch(preferredLanguageChange({ languageCode: this.translate.currentLang }));
  }

  private setupPrimeNG() {
    this.primeConfig.ripple = true;
  }

  private setUserProfile() {
    fromPromise(Auth.currentSession())
      .pipe(take(1))
      .subscribe((session) => {
        const { email, given_name, family_name } = session.getIdToken().payload;
        const profile = {
          firstName: given_name || 'First name',
          lastName: family_name || 'Last name',
          email,
        };

        this.store.dispatch(setUserProfile({ profile }));
      });
  }
}
