import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthCognitoService {
  getCurrentAuthorizedUser() {
    return from(Auth.currentAuthenticatedUser());
  }

  signIn(username: string, password: string) {
    return from(Auth.signIn({ username, password }));
  }

  completeNewPassword(user: unknown, newPassword: string) {
    return from(Auth.completeNewPassword(user, newPassword));
  }

  signOut() {
    return from(Auth.signOut());
  }
}
