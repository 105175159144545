import { Auth } from 'aws-amplify';

export const environment = {
  production: true,
  cognito: {
    auth: {
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_EvoNyEAmM',
      userPoolWebClientId: '4i53v01uavs5s6ic4efr9hf83m',
    },
  },
  api: {
    endpoints: [
      {
        name: 'CarbonBlockService',
        endpoint: 'https://api.test.carbonblock.app/v2',
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
  notifications: {
    websocketEndpoint: async () =>
      `wss://qrtixrn58l.execute-api.eu-central-1.amazonaws.com/test?idToken=${(
        await Auth.currentSession()
      )
        .getIdToken()
        .getJwtToken()}`,
  },
  translate: {
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
  },
  bomTemplateUrl:
    'https://carbonblockdatasetss3buckettest.s3.eu-central-1.amazonaws.com/BOM_Template.xlsx',
  pathfinderBaseUrl: 'https://api.test.carbonblock.app/2/footprints',
};
