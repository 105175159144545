<p-toast></p-toast>
<div class="login-page flex min-h-screen justify-content-center align-items-center login-bg">
  <div class="grid login-container flex-nowrap">
    <div class="hidden xl:block p-6 col-6 info-block">
      <section class="header-section">
        <h3 class="header-text">{{ 'SignUp.Info.Header' | translate }}</h3>
        <p class="header-sub font-normal">
          {{ 'SignUp.Info.SubHeader' | translate }}
        </p>
      </section>
      <section class="flex align-items-start info-section">
        <div class="section-icon">
          <i class="info-icon first"></i>
        </div>
        <div class="section-text">
          <h5 class="info-header-text">{{ 'SignUp.Info.Section1Header' | translate }}</h5>
          <p class="font-normal">
            {{ 'SignUp.Info.Section1Text' | translate }}
          </p>
        </div>
      </section>
      <section class="flex align-items-start info-section">
        <div class="section-icon">
          <i class="info-icon second"></i>
        </div>
        <div class="section-text">
          <h5 class="info-header-text">{{ 'SignUp.Info.Section2Header' | translate }}</h5>
          <p class="font-normal">
            {{ 'SignUp.Info.Section2Text' | translate }}
          </p>
        </div>
      </section>
      <section class="flex align-items-start info-section">
        <div class="section-icon">
          <i class="info-icon third"></i>
        </div>
        <div class="section-text">
          <h5 class="info-header-text">{{ 'SignUp.Info.Section3Header' | translate }}</h5>
          <p class="font-normal">
            {{ 'SignUp.Info.Section3Text' | translate }}
          </p>
        </div>
      </section>
    </div>
    <div class="col-10 col-offset-1 xl:col-offset-0 xl:col-6 p-6 sign-in-block">
      <div class="logo" [ngClass]="{ 'has-error': hasError$ | async }"></div>

      <h3 class="header mt-4" [ngClass]="{ 'mb-3': !isSignUpContext }">
        {{ isSignUpContext ? ('SignUp.Form.Header' | translate) : ('SignUp.PathFinder.Header' | translate) }}
      </h3>
      <p *ngIf="!isSuccessPathFinder && (hasError$ | async) === false && !isSignUpContext" class="mb-4">
        {{ 'SignUp.PathFinder.Description' | translate }}
      </p>

      <div
        *ngIf="(hasError$ | async) && isSignUpContext"
        class="p-inline-message login-error-message p-inline-message-error block h-auto mt-4"
        [innerHTML]="'SignUp.ExpiredLink.SignUpErrorExpiredLink' | translate"
      ></div>

      <div class="p-inline-message block h-auto mt-4 p-card py-5 px-3" *ngIf="isSuccessPathFinder">
        <div class="flex justify-content-center my-3">
          <img src="/assets/icons/icon-verified.svg" alt="Verified Icon" class="h-5rem" />
        </div>
        <h4 class="text-green-800 text-center">{{ 'SignUp.PathFinder.SuccessHeader' | translate }}</h4>
        <p class="text-green-800 text-center">
          {{ 'SignUp.PathFinder.SuccessBody' | translate: { uuid: pcfUuid } }}
        </p>
        <a
          class="link-color cursor-pointer flex align-items-center"
          [carbonCopyText]="pathFinderSuccessUrl"
          [href]="pathFinderSuccessUrl"
          ><span>{{ pathFinderSuccessUrl }}</span></a
        >
      </div>

      <div
        class="p-inline-message login-error-message p-inline-message-error block h-auto mt-4"
        [innerHTML]="'SignUp.PathFinder.Error' | translate"
        *ngIf="(hasError$ | async) && !isSignUpContext"
      ></div>

      <form
        [formGroup]="signUpForm"
        class="flex flex-1 flex-column justify-content-start"
        (ngSubmit)="performSignUpOrPathFinder()"
        carbonFormSubmit
        *ngIf="(hasError$ | async) || isSuccessPathFinder ? !isSuccessPathFinder && isSignUpContext : true"
      >
        <div class="field p-fluid email-field">
          <span class="p-float-label" carbonControlErrorsContainer>
            <input
              id="email"
              type="text"
              pInputText
              formControlName="username"
              carbonControlErrors
              [customErrors]="emailErrors"
            />
            <label for="email">{{ 'Global.Email' | translate }}</label>
          </span>
        </div>

        <div class="field p-fluid password-field">
          <span class="p-float-label" carbonControlErrorsContainer>
            <input
              id="password"
              type="password"
              pInputText
              formControlName="password"
              carbonControlErrors
              [customErrors]="passwordErrors"
            />
            <label for="password">{{ 'SignUp.Form.ChoosePassword' | translate }}</label>
          </span>
        </div>

        <div class="field p-fluid password-field">
          <span class="p-float-label" carbonControlErrorsContainer>
            <input
              id="confirm_password"
              type="password"
              pInputText
              formControlName="confirm_password"
              carbonControlErrors
              [customErrors]="confirmPasswordErrors"
            />
            <label for="password">{{ 'SignUp.Form.ConfirmPassword' | translate }}</label>
          </span>
        </div>

        <button
          pButton
          pRipple
          [label]="
            isSignUpContext
              ? (((isLoading$ | async)! ? 'SignUp.Form.SignUpButtonProgressText' : 'SignUp.Form.SignUpButtonText')
                | translate)
              : (((isLoading$ | async)! ? 'SignUp.PathFinder.Generating' : 'SignUp.PathFinder.Generate') | translate)
          "
          class="w-full mt-auto sign-in-button flex justify-content-center"
          [loading]="(isLoading$ | async)!"
        ></button>
      </form>
    </div>
  </div>
</div>
