import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject, debounceTime, of, shareReplay, takeUntil, tap } from 'rxjs';

import { EMAIL_PATTERN } from '../../../../core/constants/help-menu.constants';
import { AuthCognitoService } from '../../../../core/services/auth-cognito.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoadingService } from '../../../../services';
import { ErrorConfig } from '../../../../shared/directives/control-errors/control-errors.token';
import { untilDestroy } from '../../../../shared/utilities';
import { isShift } from '../../../../shared/utilities/common-util/common-utils';
import { setUserProfile } from '../../../../store/user-profile/actions/user-profile.actions';
import { FOOTER_LINK_WEBSITE_URL } from '../../login.constants';

interface LoginForm {
  username: string;
  password: string;
}

@Component({
  selector: 'carbon-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService, LoadingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends untilDestroy(class {}) implements AfterViewInit {
  loginForm: FormGroup;
  isLoading$ = this.loadingService.isLoading$;
  hasError$$ = new Subject<boolean>();
  hasError$ = this.hasError$$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  inboundId: string | null = null;
  isShift: boolean;
  isHubspotFormVisible = false;

  emailErrors: ErrorConfig = {
    required: () => 'Login.LoginForm.EmailRequiredError',
    email: () => 'Login.LoginForm.EmailPatternError',
    pattern: () => 'SignUp.Form.EmailPatternError',
  };

  passwordErrors: ErrorConfig = {
    required: () => 'Login.LoginForm.PasswordRequiredError',
  };

  websiteUrl = FOOTER_LINK_WEBSITE_URL;

  constructor(
    private router: Router,
    private authService: AuthCognitoService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private loadingService: LoadingService,
    private store: Store,
    private route: ActivatedRoute,
  ) {
    super();
    this.loginForm = this.createLoginForm();
    this.loginForm.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$$)).subscribe(() => {
      this.hasError$$.next(false);
    });
    this.route?.queryParamMap
      .pipe(
        takeUntil(this.destroy$$),
        tap((params) => {
          if (params.has('Pcf') && params.get('Pcf')) {
            this.inboundId = params.get('Pcf');
          }
        }),
      )
      .subscribe((data) => data);

    // Checks if current client is Shift
    this.isShift = isShift();
  }

  ngAfterViewInit(): void {
    this.loadHubspotForm();
  }

  login(): void {
    if (this.loginForm.valid) {
      of(this.loginForm.value)
        .pipe(
          this.loadingService.loadingSwitchMap((formData: LoginForm) =>
            this.authService.signIn(formData.username.toLowerCase(), formData.password),
          ),
        )
        .subscribe(
          (user) => {
            const { given_name, family_name, email } = user.attributes;
            const profile = {
              firstName: given_name || 'First name',
              lastName: family_name || 'Last name',
              email,
            };
            this.store.dispatch(setUserProfile({ profile }));
            if (this.inboundId) {
              this.router.navigateByUrl(`/requests/overview/inbound?Pcf=${this.inboundId}`);
            } else {
              this.router.navigateByUrl('/products/overview');
            }
          },
          (e) => {
            this.hasError$$.next(true);
            this.logger.logError(`Attempt to login: failed, ${e}`);
          },
        );
    }
  }

  private loadHubspotForm(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';

    document.body.appendChild(script);
  }

  openHubspotForm(): void {
    this.isHubspotFormVisible = true;
    try {
      const hbspt = (window as Window & typeof globalThis as any)?.hbspt;
      hbspt.forms.create({
        region: 'eu1',
        portalId: '26884870',
        formId: '42b2b726-b4a4-4875-a23f-d1ba05bd45b1',
        target: '#hubspotForm',
      });
    } catch (e) {
      this.isHubspotFormVisible = false;
    }
  }
  closeHubspotForm(): void {
    this.isHubspotFormVisible = false;
  }

  private createLoginForm() {
    return this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
      password: ['', [Validators.required]],
      rememberMe: [true],
    });
  }
}
