import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { RequestsService } from '../../../pages/requests/services/requests.service';
import { ApiToStoreCallsService } from '../../../services';
import {
  loadInboundRequests,
  loadInboundRequestsFailure,
  loadInboundRequestsSuccess,
  setProductInboundRequest,
  setProductInboundRequestFailure,
  setProductInboundRequestSuccess,
} from '../actions/product-inbound-request.actions';

@Injectable()
export class ProductInboundEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: ApiToStoreCallsService,
    private requestsService: RequestsService,
  ) {}

  loadInboundRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadInboundRequests),
      switchMap(() => this.requestsService.getInboundRequests()),
      map((response) => loadInboundRequestsSuccess({ inboundRequests: response })),
      catchError(() => of(loadInboundRequestsFailure())),
    );
  });

  setProductInboundRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setProductInboundRequest),
      switchMap(({ id }) => this.apiService.getProductInboundRequest(id)),
      map((response) => setProductInboundRequestSuccess({ product: response })),
      catchError(() => of(setProductInboundRequestFailure())),
    );
  });
}
